<template>
  <div>

    <div v-if="loading">
      Loading...
    </div>

    <div v-else>
      <header class="mt-16 mb-12">
        <div class="mb-4">
          <h1 class="text-4xl underline">{{ project.title }}</h1>
          <h2 v-if="project.subtitle" class="mt-2 text-2xl">{{ project.subtitle }}</h2>
        </div>

        <div class="flex justify-between m-2 text-md">
          <div class="flex-col text-left">
            <div>Producer</div>
            <div v-if="project.producerName">{{ project.producerName }}</div>
            <div v-else>Jai-Lee Egna</div>
          </div>

          <div class="flex-col text-right">
            <div><a href="mailto:jailee.egna@gmail.com">jailee.egna@gmail.com</a></div>
            <div>917 543 8327</div>
          </div>
        </div>
      </header>

      <div class="flex flex-row flex-wrap justify-center mb-20 align-middle">
        <div
          v-for="model in project.models"
          :key="model._id"
          class="w-full px-4 mb-12 sm:w-1/3"
        >
          <a :href="model.link" :title="model.link" target="_blank">
            <div
              v-if="model.image"
              :style="`background: url('${ imageUrlFor(model.image).width(480) }') center center no-repeat; background-size: contain; min-height: 400px;`"
            >
              <!--
              <img
                v-if="appearance.model.image"
                :src="imageUrlFor(appearance.model.image).width(480)"
                width="480"
              />
              -->
            </div>
          </a>
          <div class="my-2"><h3 class="text-xl">{{ model.name }}</h3></div>
          <SanityBlocks :blocks="model.notes" />
        </div>
      </div>
    </div>

    <div class="mt-20 mb-8 text-center">
      <a href="http://production.jai-lee.com" class="underline">production.jai-lee.com</a>
    </div>

  </div>

</template>

<script>

import { SanityBlocks } from "sanity-blocks-vue-component";
import sanity from '../client'
import imageUrlBuilder from "@sanity/image-url";
const imageBuilder = imageUrlBuilder(sanity);

const query = `*[slug.current == $slug] {
  _id,
  title,
  slug,
  subtitle,
  producerName,
  models[]->{...},
}[0]
`;

//import { useSanityFetcher } from 'vue-sanity'

export default {
  name: "ProjectPage",
  components: { SanityBlocks },
  data() {
    return {
      loading: true,
      project: [],
      // blocks: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    imageUrlFor(source) {
      return imageBuilder.image(source);
    },

    fetchData() {
      this.error = this.post = null;
      this.loading = true;

      sanity.fetch(query, { slug: this.$route.params.slug }).then(
        (project) => {
          this.loading = false;
          this.project = project;
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },
};

</script>
